// @flow
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import '../components/icons/FontAwesome';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';

import ScrollToTop from './ScrollToTop';
import asyncComponent from '../components/core/asyncComponent';

import userStore from '../modules/user/UserStore';
import baseStore from '../modules/base/BaseStore';
import authStore from '../modules/auth/AuthStore';
import configStore from '../modules/config/ConfigStore';
import learningPathwayStore from '../modules/learning-pathway/LearningPathwayStore';
import courseStore from '../modules/course/CourseStore';
import resourceStore from '../modules/resource/ResourceStore';
import programStore from '../modules/program/ProgramStore';
import notificationsStore from '../modules/notifications/NotificationsStore';
import revisionStore from '../modules/revision/RevisionStore';
import locationStore from '../modules/location/LocationStore';
import lessonStore from '../modules/lesson/LessonStore';
import searchStore from '../modules/search/SearchStore';
import contentGroupStore from '../modules/content-group/ContentGroupStore';
import projectContentStore from '../modules/project-content/ProjectContentStore';
import momentStore from '../modules/moment/MomentStore';
import simulationStore from '../modules/simulation/SimulationStore';
import contentAreaStore from '../modules/content-area/ContentAreaStore';
import customerStore from '../modules/customer/CustomerStore';
import builderStore from '../modules/builder/BuilderStore';
import screenStore from '../modules/screen/ScreenStore';
import canvasStore from '../modules/canvas/CanvasStore';
import invitationStore from '../modules/invitation/InvitationStore';
import imageStore from '../modules/image/ImageStore';
import certificationStore from '../modules/certification/CertificationStore';
import layerStore from '../modules/layer/LayerStore';
import tagStore from '../modules/tag/TagStore';
import skillsAIStore from '../modules/skillsAI/SkillsAIStore';
import teamsStore from '../modules/teams/TeamsStore';
import assignmentStore from '../modules/assignment/AssignmentStore';
import userManagementStore from '../modules/user-management/UserManagementStore';
import welcomePlacematStore from '../modules/welcome-placemat/WelcomePlacematStore';
import AIConversationBuilderStore from '../modules/ai-conversation-builder/AIConversationBuilderStore';
import badgeStore from '../modules/badge/badgeStore';
import AnnouncementStore from '../modules/announcement/AnnouncementStore';

const App = asyncComponent(() => import(/* webpackChunkName: "app" */ './App'));
const Login = asyncComponent(() => import(/* webpackChunkName: "login" */ './Login'));

const history = createBrowserHistory();

const routes = [
  { path: '/login' },
  { path: '/' },
];

const Root = () => {
  document.title = 'Bright | Admin';

  return (
    <Router>
      <Provider
        userStore={userStore}
        baseStore={baseStore}
        authStore={authStore}
        programStore={programStore}
        configStore={configStore}
        learningPathwayStore={learningPathwayStore}
        courseStore={courseStore}
        notificationsStore={notificationsStore}
        revisionStore={revisionStore}
        locationStore={locationStore}
        resourceStore={resourceStore}
        searchStore={searchStore}
        lessonStore={lessonStore}
        contentGroupStore={contentGroupStore}
        projectContentStore={projectContentStore}
        momentStore={momentStore}
        customerStore={customerStore}
        builderStore={builderStore}
        screenStore={screenStore}
        canvasStore={canvasStore}
        invitationStore={invitationStore}
        imageStore={imageStore}
        simulationStore={simulationStore}
        contentAreaStore={contentAreaStore}
        certificationStore={certificationStore}
        layerStore={layerStore}
        tagStore={tagStore}
        skillsAIStore={skillsAIStore}
        teamsStore={teamsStore}
        assignmentStore={assignmentStore}
        badgeStore={badgeStore}
        userManagementStore={userManagementStore}
        welcomePlacematStore={welcomePlacematStore}
        AIConversationBuilderStore={AIConversationBuilderStore}
        announcementStore={AnnouncementStore}
      >
        <ScrollToTop>
          <Switch>
            <Route
              path="/login"
              component={Login}
            />
            <Route
              path="/"
              component={App}
            />
          </Switch>
        </ScrollToTop>
      </Provider>
    </Router>
  );
};

export default Root;
