// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';

class AIConversationBuilderStore {
  voices = [];

  builderData = null;

  isBuilderLoading = false;

  contentAreasWithPublishedConversations = [];

  nodesOfActiveConversation = null;

  getVoices = async () => {
    try {
      if (!this.voices.length) {
        const { data } = await Api.getVoices();

        if (data) {
          this.voices = data;
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  getAIConversationBuilder = async (contentAreaId, loading = true) => {
    try {
      if (loading) {
        this.isBuilderLoading = true;
      }

      const { data } = await Api.getAIConversationBuilder(contentAreaId);
      this.builderData = data;
      this.isBuilderLoading = false;
      return this.builderData;
    } catch (err) {
      throw new Error(err);
    }
  };

  getAIConversationBuilderVersions = async ({ contentAreaId }) => {
    try {
      const {
        data,
      } = await Api.getAIConversationBuilderVersions(contentAreaId);

      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  getAIConversationBuilderAnalysisData = async ({ contentAreaId, versionId = null }) => {
    try {
      const {
        data: { builderData, message },
      } = await Api.getAIConversationBuilderAnalysisData({ contentAreaId, versionId });

      if (builderData) {
        this.builderData = builderData;
      }

      return { builderData, message };
    } catch (err) {
      throw new Error(err);
    }
  };

  getVersionedNode = async ({ nodeUUID, versionId }) => {
    try {
      const {
        data: { nodeData },
      } = await Api.getVersionedNode({ nodeUUID, versionId });

      return { nodeData };
    } catch (err) {
      throw new Error(err);
    }
  };

  updateAIConversationBuilderData = async (
    contentAreaId,
    versionId,
    stages,
    conversationType,
    avatar,
    voiceId,
    voiceType,
    voiceStability,
    voiceSimilarity,
    voiceStyleExaggeration,
  ) => {
    try {
      const { data } = await Api.updateAIConversationBuilderData(
        contentAreaId,
        versionId,
        stages,
        conversationType,
        avatar,
        voiceId,
        voiceType,
        voiceStability,
        voiceSimilarity,
        voiceStyleExaggeration,
      );

      const dataResultIndex = data.indexOf('{"data"');

      if (dataResultIndex !== -1) {
        const dataResultString = data.slice(dataResultIndex);

        try {
          const dataObject = JSON.parse(dataResultString);

          this.builderData = dataObject?.data;
          return this.builderData;
        } catch (error) {
          console.error('Failed to parse JSON from data:', error);
          return null;
        }
      } else {
        console.log('No data found in the response.');
        return null;
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  updateAIRulesForBuilder = async ({ nodesToBeSendToInMoment }) => {
    try {
      await Api.updateAIRulesForBuilder(
        nodesToBeSendToInMoment,
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  publishAIConversation = async ({ versionId, voiceId }) => {
    try {
      const { data: { builderData, message } } = await Api.publishAIConversation({
        contentAreaId: this.builderData.contentAreaId,
        versionId,
        voiceId,
      });

      if (builderData) this.builderData = builderData;

      return { builderData, message };
    } catch (err) {
      throw new Error(err);
    }
  };

  uploadAvatar = async (avatarFileData) => {
    const { data } = await Api.uploadAvatarForAIConversation(
      avatarFileData,
      this.builderData.id,
    );
    return data;
  }

  clearBuilderData = async () => {
    this.builderData = null;
  }

  getContentAreasWithPublishedAIConversations = async () => {
    try {
      const { data: contentAreas } = await Api.getContentAreasWithPublishedAIConversations();
      this.contentAreasWithPublishedConversations = contentAreas
        .sort((a, b) => a.Simulation.name.localeCompare(b.Simulation.name));
    } catch (err) {
      throw new Error(err);
    }
  }

  getNodesByContentArea = async (contentAreaId) => {
    try {
      const { data: nodes } = await Api.getNodesByContentArea(contentAreaId);
      this.nodesOfActiveConversation = nodes;
    } catch (err) {
      throw new Error(err);
    }
  }
}

decorate(AIConversationBuilderStore, {
  voices: observable,
  builderData: observable,
  isBuilderLoading: observable,
  contentAreasWithPublishedConversations: observable,
  nodesOfActiveConversation: observable,
  getVoices: action,
  getAIConversationBuilder: action,
  getAIConversationBuilderVersions: action,
  getAIConversationBuilderAnalysisData: action,
  getVersionedNode: action,
  updateAIConversationBuilderData: action,
  updateAIRulesForBuilder: action,
  publishAIConversation: action,
  clearBuilderData: action,
  getContentAreasWithPublishedAIConversations: action,
  getNodesByContentArea: action,
});

export default new AIConversationBuilderStore();
