export const application = 'bright-dev';

export const apiURL = '/bright/api';

export const rootUrl = 'https://admin-dev.learnwithbright.com';

export const vimeoClientId = '15a9673c81d2f261fca256dcabec4c439e215a55';

export const vimeoRedirect = 'https://admin-dev.learnwithbright.com/bright/api/vimeo/callback';

export const academyURL = 'https://app-dev.learnwithbright.academy';
