// @flow
import { decorate, observable, action } from 'mobx';

import Api from '../../api';

class SimulationStore {
  constructor() {
    this.simulations = [];
    this.simulationsList = [];
    this.totalCount = 0;
    this.selectedSimulation = null;
    this.creatingSimulation = false;
    this.updatingSimulation = false;
    this.fetchSimulationState = null;
  }

  getSimulations = async (queryParams) => {
    const { data } = await Api.getSimulations(queryParams);
    if (data) {
      this.simulations = data.simulations;
      this.totalCount = data.totalCount;
    }

    return this.simulations;
  }

  getSimulationsList = async () => {
    try {
      if (this.simulationsList?.length) return this.simulationsList;

      const { data } = await Api.getSimulationsList();
      if (data?.simulationsList?.length) {
        this.simulationsList = data.simulationsList;
      }

      return this.simulationsList;
    } catch (err) {
      return [];
    }
  }

  getSimulation = async (simulationId) => {
    try {
      const { data } = await Api.getSimulation(simulationId);

      this.selectedSimulation = data;

      return data;
    } catch (e) {
      this.fetchSimulationState = e.response.status;
    }
  }

  createSimulation = async (simulation) => {
    this.creatingSimulation = true;
    let data = null;
    try {
      ({ data } = await Api.createSimulation(simulation));
    } catch (error) {
      throw new Error(error);
    } finally {
      this.creatingSimulation = false;
    }
    return data;
  }

  updateSimulation = async (simulation) => {
    this.updatingSimulation = true;
    await Api.updateSimulation(simulation);
    this.updatingSimulation = false;
  }

  deleteSimulation = async (simulationId) => {
    const { data } = await Api.deleteSimulation(simulationId);
    await this.getSimulations();

    return data;
  }

  deleteSimulations = async (simulationIds) => {
    await Api.deleteSimulations(simulationIds);
    await this.getSimulations();
  }

  bulkTagSimulations = async ({ selectedTeamIds, simulationIds }) => {
    try {
      await Api.bulkTagSimulations({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        simulationIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }
}

decorate(SimulationStore, {
  simulations: observable,
  selectedSimulation: observable,
  simulationsList: observable,
  getSimulations: action,
  getSimulationsList: action,
  getSimulation: action,
  createSimulation: action,
  updateSimulation: action,
  deleteSimulation: action,
  deleteSimulations: action,
  bulkTagSimulations: action,
});

export default new SimulationStore();
