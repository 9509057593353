/* eslint-disable class-methods-use-this */
// @flow
import axios from 'axios';
import config from './config';
import authStore from './modules/auth/AuthStore';

const POST = 'post';
const GET = 'get';
const PUT = 'put';
const DELETE = 'delete';

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = config.apiURL;
  api.interceptors.request.use((config) => {
    if (window.location.href.includes('eidos')) {
      config.params = { ...config.params, eidos: true };
    }

    return config;
  });


  return api;
};

const axiosApi = initApi();

const redirectOn401 = (error) => {
  if (error.response.data) {
    if (error.response.data.ssoUrl) {
      window.location.href = (
        error.response.data.ssoUrl + window.location.pathname + window.location.search
      );
    }

    window.location.href = `${config.rootUrl}/login`;
  }
};

const getBody = (verb, body) => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb, url, body, additionalConfig) => {
  try {
    const requestConfig = {
      url,
      method: verb,
      data: getBody(verb, body),
      withCredentials: true, // include existing cookies as part of request header
      ...additionalConfig,
    };
    const { status, headers, data } = await axiosApi.request(requestConfig);

    // reset auth session expiry on successful API return
    authStore.resetExpiryDate();

    return {
      status,
      headers,
      data,
    };
  } catch (error) {
    const { status } = error.response;
    // handle 401s in the store, payload may contain relevant redirect info

    if (status === 401) {
      redirectOn401(error);
    }

    if (status === 409) {
      return status;
    }

    throw error;
  }
};

const getPaginationAndSortingQueries = (queryParams = {}) => {
  const {
    pageIndex,
    pageSize,
    sortKey,
    isAscOrder,
  } = queryParams;
  const queries = [];

  if (pageIndex >= 0) {
    queries.push(`pageIndex=${pageIndex}`);
  }

  if (pageSize >= 0) {
    queries.push(`pageSize=${pageSize}`);
  }

  if (sortKey) {
    queries.push(`sortKey=${sortKey}`);
  }

  if (isAscOrder) {
    queries.push(`isAscOrder=${isAscOrder}`);
  }

  return queries;
};

const buildQueryForUsersListing = (queryParams = {}) => {
  const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
  const queries = [...paginationAndSoringQueries];

  const {
    searchText,
    selectedTierFourTeams,
    selectedTierThreeTeams,
    selectedTierTwoTeams,
    selectedTierOneTeams,
    selectedAssignments,
    selectedExploreAssignments,
    selectedPermissionLevels,
    selectedRoles,
    selectedUserStatus,
  } = queryParams;

  if (searchText) {
    queries.push(`searchText=${searchText}`);
  }

  const selectedTeams = [];
  const paths = new Set();

  const extractSelectedTeamsAndPaths = (tierTeams) => {
    if (
      Array.isArray(tierTeams)
      && tierTeams.length >= 1
    ) {
      selectedTeams.forEach((team) => {
        const pathArr = team.path.split('/').filter((t) => t && !Number.isNaN(Number(t))).map((t) => Number(t));
        pathArr.forEach((path) => paths.add(path));
      });

      tierTeams.forEach((t) => {
        if (!paths.has(t.id)) {
          selectedTeams.push(t);
        }
      });
    }
  };

  extractSelectedTeamsAndPaths(selectedTierFourTeams);
  extractSelectedTeamsAndPaths(selectedTierThreeTeams);
  extractSelectedTeamsAndPaths(selectedTierTwoTeams);
  extractSelectedTeamsAndPaths(selectedTierOneTeams);

  if (selectedTeams && selectedTeams.length) {
    queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id).join(',')}`);
  }

  if (
    Array.isArray(selectedAssignments)
    && selectedAssignments.length >= 1
  ) {
    queries.push(`selectedAssignments=${selectedAssignments.map(({ id }) => id).join(',')}`);
  }

  if (
    Array.isArray(selectedExploreAssignments)
    && selectedExploreAssignments.length >= 1
  ) {
    queries.push(`selectedExploreAssignments=${selectedExploreAssignments.map(({ id }) => id).join(',')}`);
  }

  if (
    Array.isArray(selectedPermissionLevels)
    && selectedPermissionLevels.length >= 1
  ) {
    queries.push(`selectedPermissionLevels=${selectedPermissionLevels.map(({ id }) => id).join(',')}`);
  }

  if (Array.isArray(selectedRoles) && selectedRoles.length >= 1) {
    queries.push(`selectedRoles=${selectedRoles.map(({ id }) => id).join(',')}`);
  }

  if (selectedUserStatus) {
    queries.push(`selectedUserStatus=${selectedUserStatus.id}`);
  }

  if (queries.length) {
    return `?${queries.join('&')}`;
  }

  return '';
};

class BrightApi {
  async login(username) {
    return execute(POST, '/login', { username, password: 'password' });
  }

  async logout() {
    return execute(GET, '/logout');
  }

  async getUserProfile() {
    return execute(GET, '/users/me');
  }

  async getUsers() {
    return execute(GET, '/users');
  }

  async keepAlive() {
    return execute(GET, '/auth/keepAlive');
  }

  async getConfig() {
    return execute(GET, '/config');
  }

  async getBaseData() {
    return execute(GET, '/base?readFromCache=false');
  }

  async createBaseValue(type, body) {
    return execute(POST, `/base?type=${type}`, body);
  }

  async updateBaseValue(type, body) {
    return execute(PUT, `/base/${body.id}?type=${type}`, body);
  }

  async deleteBaseValue(type, body) {
    return execute(DELETE, `/base/${body.id}?type=${type}`, body);
  }

  async getLearningPathways() {
    return execute(GET, '/learning-pathway');
  }

  async getLearningPathway(id) {
    return execute(GET, `/learning-pathway/${id}?include=["resources"]`);
  }

  async getContentGroups() {
    return execute(GET, '/content-group');
  }

  async getProjectContent(projectContentId) {
    return execute(GET, `/project-content?project=${projectContentId}`);
  }

  async getProjectContentById(projectContentId) {
    return execute(GET, `/project-content/${projectContentId}`);
  }

  async createProjectContent(payload, project) {
    return execute(POST, `/project-content?project=${project}`, payload);
  }

  async deleteProjectContent(resourceId) {
    return execute(DELETE, `/project-content/${resourceId}`);
  }

  async getContentGroup(id) {
    return execute(GET, `/content-group/${id}`);
  }

  async deleteContentGroup(resourceId) {
    return execute(DELETE, `/content-group/${resourceId}`);
  }

  async createContentGroup(lp) {
    return execute(POST, '/content-group', lp);
  }

  async updateContentGroup(lp) {
    return execute(PUT, `/content-group/${lp.id}`, lp);
  }

  async getCourses() {
    return execute(GET, '/course?include=["lessons"]');
  }

  async getLessons(readFromCache) {
    if (readFromCache) {
      return execute(GET, '/lesson?readFromCache=false');
    }

    return execute(GET, '/lesson?readFromCache=false');
  }

  async getResources(queryParams = {}) {
    const queries = getPaginationAndSortingQueries(queryParams);

    const {
      searchText,
      selectedType,
      selectedHomePageOption,
      selectedTeams,
    } = queryParams;

    if (searchText) {
      queries.push(`searchText=${searchText}`);
    }

    if (selectedType) {
      queries.push(`selectedType=${selectedType.name}`);
    }

    if (selectedHomePageOption) {
      queries.push(`selectedHomePageOption=${selectedHomePageOption.id}`);
    }

    if (selectedTeams?.length) {
      queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id)}`);
    }

    return execute(GET, `/admin/resource?${queries.join('&')}`);
  }

  async getResource(id) {
    return execute(GET, `/admin/resource/${id}`);
  }

  async createResource(resource) {
    return execute(POST, '/admin/resource', resource);
  }

  async deleteResource(resourceId) {
    return execute(DELETE, `/admin/resource/${resourceId}`);
  }

  async deleteResources(resourceIds) {
    return execute(DELETE, `/admin/resource?ids=${resourceIds}`);
  }

  async bulkTagResources({ selectedTeamIds, resourceIds }) {
    return execute(POST, '/admin/resource/bulkTag', { selectedTeamIds, resourceIds });
  }

  async deleteLearningPathway(resourceId) {
    return execute(DELETE, `/learning-pathway/${resourceId}`);
  }

  async search(obj, limit) {
    return execute(GET, `/search?limit=${limit}&filter=${JSON.stringify({ term: encodeURIComponent(obj) })}`);
  }

  async updateResource(resource) {
    return execute(PUT, `/admin/resource/${resource.id}`, resource);
  }

  async createCourse(course) {
    return execute(POST, '/course', course);
  }

  async updateCourse(course) {
    return execute(PUT, `/course/${course.id}`, course);
  }

  async createLesson(lesson) {
    return execute(POST, '/lesson', lesson);
  }

  async updateLesson(lesson) {
    return execute(PUT, `/lesson/${lesson.id}`, lesson);
  }

  async deleteLesson(lessonId) {
    return execute(DELETE, `/lesson/${lessonId}`, {});
  }

  async getCurricula() {
    return execute(GET, '/curriculum?include=["brands","courses","systemVariations","jobFunctions"]');
  }

  async getLocations() {
    return execute(GET, '/location?include=["systemVariations"]');
  }

  async getLocationByCode(locationCode) {
    return execute(GET, `/location/${locationCode}?include=["systemVariations"]`);
  }

  async getPrograms() {
    return execute(GET, '/programs?include=["brands","levels","regions"]');
  }

  async getProgramByProgramId(programId) {
    return execute(GET, `/programs/${programId}?include=["brands","levels","regions","jobFunctions"]`);
  }

  async updateProgram(program) {
    return execute(PUT, `/programs/${program.id}`, program);
  }

  async uploadCourseFile(resourceId, uploadData) {
    return execute(POST, `/files?resourceId=${resourceId}`, uploadData);
  }

  async getResourceFiles(resourceId) {
    return execute(GET, `/files?resourceId=${resourceId}`);
  }

  async createUser(user) {
    return execute(POST, '/users', user);
  }

  async refreshUsersFromSpreadsheet(user) {
    return execute(GET, '/users/refreshFromSource');
  }

  async refreshAssignmentsFromSpreadsheet(user) {
    return execute(GET, '/users/refreshAssignmentsFromSource');
  }

  async updateUser(user) {
    return execute(PUT, `/users/${user.id}`, user);
  }

  async updateCurrentProject(currentProject) {
    return execute(PUT, '/users/me', {
      preferences: {
        currentProject,
      },
    });
  }

  async getRevisions(type, id, since = new Date(0)) {
    const params = {
      type,
      id,
      since,
    };

    return execute(GET, '/revision', {}, { params });
  }

  async getReportData(reportType, inputs) {
    switch (reportType) {
      case 'user-activity':
        return execute(GET, '/reports/user-activity');
      default:
        throw new Error(`unsupported report type: ${reportType}`);
    }
  }

  async getSimulations(queryParams = {}) {
    const queries = getPaginationAndSortingQueries(queryParams);

    const {
      searchText,
      selectedTeams,
    } = queryParams;

    if (selectedTeams?.length) {
      queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id)}`);
    }

    if (searchText) {
      queries.push(`searchText=${searchText}`);
    }

    return execute(GET, `/admin/simulation?${queries.join('&')}`);
  }

  async getSimulationsList() {
    return execute(GET, '/admin/simulations/list');
  }

  async getSimulation(simulationId) {
    return execute(GET, `/admin/simulation/${simulationId}`);
  }

  async createSimulation(simulation) {
    return execute(POST, '/admin/simulation', simulation);
  }

  async updateSimulation(simulation) {
    return execute(PUT, `/admin/simulation/${simulation.id}`, simulation);
  }

  async deleteSimulation(simulationId) {
    return execute(DELETE, `/admin/simulation/${simulationId}`);
  }

  async deleteSimulations(simulationIds) {
    return execute(DELETE, `admin/simulation/?ids=${simulationIds}`);
  }

  async bulkTagSimulations({ selectedTeamIds, simulationIds }) {
    return execute(POST, '/admin/simulation/bulkTag', { selectedTeamIds, simulationIds });
  }

  async getMoments(queryParams = {}) {
    const queries = getPaginationAndSortingQueries(queryParams);

    const {
      fields,
      searchText,
      selectedTeams,
      selectedType,
      selectedAIStatus,
      selectedHomePageOption,
      selectedSkills,
    } = queryParams;

    if (selectedSkills?.length) {
      queries.push(`selectedSkills=${selectedSkills.map(({ id }) => id)}`);
    }

    if (selectedTeams?.length) {
      queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id)}`);
    }

    if (selectedType) {
      queries.push(`selectedType=${selectedType.id}`);
    }

    if (selectedAIStatus) {
      queries.push(`selectedAIStatus=${selectedAIStatus.id}`);
    }

    if (selectedHomePageOption) {
      queries.push(`selectedHomePageOption=${selectedHomePageOption.id}`);
    }

    if (searchText) {
      queries.push(`searchText=${searchText}`);
    }

    if (fields) {
      queries.push(`fields=${fields.join(',')}`);
    }

    return execute(GET, `/admin/moment?${queries.join('&')}`);
  }

  async deleteMoments(momentIds) {
    return execute(DELETE, `admin/moment/?ids=${momentIds}`);
  }

  async createMoment(moment) {
    return execute(POST, 'admin/moment', moment);
  }

  async updateMoment(moment) {
    return execute(PUT, `admin/moment/${moment.id}`, moment);
  }

  async getMoment(momentId) {
    return execute(GET, `admin/moment/${momentId}`);
  }

  async deleteMoment(momentId) {
    return execute(DELETE, `admin/moment/${momentId}`);
  }

  async bulkTagMoments({ selectedTeamIds, momentIds }) {
    return execute(POST, '/admin/moment/bulkTag', { selectedTeamIds, momentIds });
  }

  async getCertifications(queryParams = {}) {
    const queries = getPaginationAndSortingQueries(queryParams);

    const {
      searchText,
      selectedTeams,
      selectedType,
    } = queryParams;

    if (selectedTeams?.length) {
      queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id)}`);
    }

    if (selectedType) {
      queries.push(`selectedType=${selectedType.value}`);
    }

    if (searchText) {
      queries.push(`searchText=${searchText}`);
    }

    return execute(GET, `/admin/certification?${queries.join('&')}`);
  }

  async deleteCertifications(certificationIds) {
    return execute(DELETE, `admin/certification/?ids=${certificationIds}`);
  }

  async createCertification(certification) {
    return execute(POST, 'admin/certification', certification);
  }

  async updateCertification(certification) {
    return execute(PUT, `admin/certification/${certification.id}`, certification);
  }

  async getCertification(certificationId) {
    return execute(GET, `admin/certification/${certificationId}`);
  }

  async deleteCertification(certificationId) {
    return execute(DELETE, `admin/certification/${certificationId}`);
  }

  async bulkTagCertifications({ selectedTeamIds, certificationIds }) {
    return execute(POST, '/admin/certification/bulkTag', { selectedTeamIds, certificationIds });
  }

  async getInvitations() {
    return execute(GET, '/invitations');
  }

  async getInvitation(invitationId) {
    return execute(GET, `/invitations/${invitationId}`);
  }

  async createInvitation(moment) {
    return execute(POST, '/invitations', moment);
  }

  async updateInvitation(moment) {
    return execute(PUT, `/invitations/${moment.id}`, moment);
  }

  async deleteInvitation(momentId) {
    return execute(DELETE, `/invitations/${momentId}`);
  }

  async getContent() {
    return execute(GET, '/content?admin=true');
  }

  async getContentItem(contentId) {
    return execute(GET, `/content/${contentId}`);
  }

  async impersonateCustomer(customer) {
    return execute(GET, `/admin/login?customerId=${customer.id}`);
  }

  async getScreen(screenId) {
    return execute(GET, `/screens/${screenId}`);
  }

  async getScreens() {
    return execute(GET, '/screens');
  }

  async getScreensPaginated({
    search,
    page = 0,
    pageSize = 10,
    screenGroupId = [],
  }) {
    return execute(GET, `/screens-paginated?search=${search ?? ''}&screenGroupId=${screenGroupId}&page=${page}&pageSize=${pageSize}`);
  }

  async getCanvases(queryParams = {}) {
    const queries = getPaginationAndSortingQueries(queryParams);

    const {
      searchText,
      selectedTeams,
    } = queryParams;

    if (selectedTeams?.length) {
      queries.push(`selectedTeams=${selectedTeams.map(({ id }) => id)}`);
    }

    if (searchText) {
      queries.push(`searchText=${searchText}`);
    }

    return execute(GET, `/admin/canvas?${queries.join('&')}`);
  }

  async getCanvas(canvasId) {
    return execute(GET, `/admin/canvas/${canvasId}`);
  }

  async deleteCanvas(canvasId) {
    return execute(DELETE, `/admin/canvas/${canvasId}`);
  }

  async deleteCanvases(canvasIds) {
    return execute(DELETE, `/admin/canvas?ids=${canvasIds}`);
  }

  async bulkTagCanvases({ selectedTeamIds, canvasIds }) {
    return execute(POST, '/admin/canvas/bulkTag', { selectedTeamIds, canvasIds });
  }

  async createCanvas(canvas) {
    return execute(POST, '/admin/canvas', canvas);
  }

  async cloneCanvas(screen) {
    return execute(POST, '/screen-groups/clone', screen);
  }

  async updateCanvas(canvas) {
    return execute(PUT, `/admin/canvas/${canvas.id}`, canvas);
  }

  async createScreenFromUpload(uploadData, options) {
    return execute(POST, '/screens/batch', uploadData, { params: options });
  }

  async createScreen(screen) {
    return execute(POST, '/screens', screen);
  }

  async updateScreen(payload, options) {
    return execute(PUT, `/screens/${options.screenId}?type=${options.type}`, payload);
  }

  async updateScreens(payload, options) {
    return execute(PUT, '/screens/', payload);
  }

  async deleteScreen(screenId) {
    return execute(DELETE, `/screens/${screenId}`);
  }

  async postScreenDefaultStyles(screenId, styles) {
    return execute(POST, `/screen-default-font-style/${screenId}`, styles);
  }

  async getImage(imageId) {
    return execute(GET, `/images/${imageId}`);
  }

  async getImages() {
    return execute(GET, '/files?type=image');
  }

  async createLayer(layer) {
    return execute(POST, '/layers', layer);
  }

  async updateLayer(layerId, layer) {
    return execute(PUT, `/layers/${layerId}`, layer);
  }

  async getLayers() {
    return execute(GET, '/layers');
  }

  async deleteLayer(layerId) {
    return execute(DELETE, `/layers/${layerId}`);
  }

  async createLayerGroup(layerGroup, layerId) {
    return execute(POST, `/layer-groups?layer_id=${layerId}`, layerGroup);
  }

  async deleteLayerGroup(layerGroupId) {
    return execute(DELETE, `/layer-group/${layerGroupId}`);
  }

  async updateLayerGroup(layerGroupId, toUpdate) {
    return execute(PUT, `/layer-groups/${layerGroupId}`, toUpdate);
  }

  async getLayerGroups() {
    return execute(GET, '/layer-groups');
  }

  async createImageFromUpload(uploadData) {
    return execute(POST, '/files?type=image', uploadData);
  }

  async createImage(image) {
    return execute(POST, '/files?type=image', image);
  }

  async updateImage(image) {
    return execute(PUT, `/images/${image.id}`, image);
  }

  async deleteImage(imageId) {
    return execute(DELETE, `/images/${imageId}`);
  }

  async uploadRecording(recording) {
    return execute(POST, '/files/recording', recording, { 'Content-Type': 'multipart/form-data' });
  }

  async uploadMomentAudio(uploadData) {
    return execute(POST, '/files?type=audio', uploadData, { 'Content-Type': 'multipart/form-data' });
  }

  async uploadMomentMedia(uploadData, type) {
    return execute(POST, '/files', uploadData, {
      'Content-Type': 'multipart/form-data',
      params: { type },
    });
  }

  async uploadVimeo(uploadData, additionalConfig) {
    return execute(POST, '/vimeo/upload', uploadData, additionalConfig);
  }

  async downloadVimeoVideo(videoId) {
    return execute(GET, `/vimeo/link/${videoId}`).then(
      ({ data }) => {
        const link = document.createElement('a');
        link.href = data.link;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      },
    );
  }

  async undoImpersonation(userId) {
    return execute(GET, `/admin/login?userId=${userId}`);
  }

  async uploadFiles(uploadData, customerFileUpload = false) {
    const queryParams = customerFileUpload ? `?customerFileUpload=${true}` : '';

    return execute(POST, `/files${queryParams}`, uploadData);
  }

  async uploadRichTextResource(uploadData) {
    return execute(POST, '/files/rich-text-resource', uploadData);
  }

  async getTagCategories() {
    return execute(GET, '/tag-categories');
  }

  async createTagCategory(tag) {
    return execute(POST, '/tag-categories', tag);
  }

  async updateTagCategory(tag) {
    return execute(PUT, `/tag-categories/${tag.id}`, tag);
  }

  async deleteTagCategory(tag) {
    return execute(DELETE, `/tag-categories/${tag.id}`);
  }

  async getTags(type = 'skill') {
    return execute(GET, `/tags?${type ? `type=${type}` : ''}`);
  }

  async createTag(tag, categoryId) {
    return execute(POST, `/tags?categoryId=${categoryId}`, tag);
  }

  async updateTag(tag) {
    return execute(PUT, `/tags/${tag.id}`, tag);
  }

  async deleteTag(tag) {
    return execute(DELETE, `/tags/${tag.id}`);
  }

  // will be removed
  async getAllTeamsOverviewData(queryParams) {
    const queries = [];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/v2/teams/overview${queryString}`);
  }

  // this func will get called when user wants to fetch teams from parent id and tier id
  async getAllTeamsListing(queryParams, insertPaginationParams = true) {
    let queries = [];
    if (insertPaginationParams) {
      const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
      queries = [...paginationAndSoringQueries];
    }

    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }
    if (queryParams.teamsId) {
      queries.push(`id=${queryParams.teamsId}`);
    }
    if (queryParams.id) {
      queries.push(`parentId=${queryParams.id}`);
    }
    if (queryParams.tierId) {
      queries.push(`tier=${queryParams.tierId + 1}`);
    }
    if (queryParams.getMembersInfo || queryParams.getMembersInfo === false) {
      queries.push(`getMembersInfo=${false}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/v2/teams/listing${queryString}`);
  }

  async getTierOneTeams() {
    return execute(GET, '/v2/teams/listing/tierOne');
  }

  async getTierBasedListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }
    if (queryParams.teamsId) {
      queries.push(`id=${queryParams.teamsId}`);
    }
    if (queryParams.id) {
      queries.push(`parentId=${queryParams.id}`);
    }
    if (queryParams.tierId) {
      queries.push(`tier=${queryParams.tierId + 1}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/v2/teams/tierBasedListing/${queryString}`);
  }

  async getSelectAllTeams(queryParams) {
    return execute(POST, 'v2/teams/getSelectAllTierTeams', queryParams);
  }

  async getTierPathNames(queryParams) {
    return execute(POST, '/v2/teams/tierPathNames', queryParams);
  }

  async fetchFilteredTeams(selectedFilters) {
    return execute(POST, '/v2/teams/listing-filters', selectedFilters);
  }

  async fetchFilteredTeamsBySearchTerm(selectedFilters) {
    return execute(POST, '/v2/teams/listing-filters-search', selectedFilters);
  }

  async createTeam(team) {
    return execute(POST, '/v2/teams/createTeam', team);
  }

  async createNewTeam(teamData) {
    return execute(POST, '/teams', teamData);
  }

  async deleteTeam(teamID) {
    return execute(DELETE, `/teams/${teamID}`);
  }

  async deleteTierTeam(teamsData) {
    return execute(DELETE, '/teams/deleteTierTeam', teamsData);
  }

  async updateTeam(teamData) {
    return execute(PUT, `/teams/${teamData.id}`, teamData);
  }

  async updateTierTeam(teamsData) {
    return execute(PUT, '/teams/updateTierTeam', teamsData);
  }

  // Assignments

  async getResourceListOverviewData(filterParams) {
    const queries = Object.keys(filterParams).map((filterParam) => (
      `${filterParam}=${filterParams[filterParam]}`
    ));
    const queryString = `?${queries.filter((query) => query.indexOf('=') !== query.length - 1).join('&')}`;

    return execute(GET, `/assignments/resources/overview${queryString}`);
  }

  async getResourceListing(filterParams, paginationParams) {
    const filterQueries = Object.keys(filterParams).map((filterParam) => (
      `${filterParam}=${filterParams[filterParam]}`
    ));
    const paginationQueries = Object.keys(paginationParams).map((paginationParam) => (
      `${paginationParam}=${paginationParams[paginationParam]}`
    ));
    const queryString = `?${[...filterQueries, ...paginationQueries].filter((query) => query.indexOf('=') !== query.length - 1).join('&')}`;

    return execute(GET, `/assignments/resources/listing${queryString}`);
  }

  async getAssignmentsOverviewData(queryParams) {
    const queries = [];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/assignments/overview${queryString}`);
  }

  async getAssignmentsListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams?.selectedTeams?.length) {
      queries.push(`selectedTeams=${queryParams.selectedTeams.map(({ id }) => id).join(',')}`);
    }

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/assignments/listing${queryString}`);
  }

  async createNewAssignment(assignmentData) {
    return execute(POST, '/assignments', assignmentData);
  }

  async deleteAssignment(assignmentID) {
    return execute(DELETE, `/assignments/${assignmentID}`);
  }

  async updateAssignment(assignmentData) {
    return execute(PUT, `/assignments/${assignmentData.id}`, assignmentData);
  }

  async getAssignmentById(assignmentId) {
    return execute(GET, `/assignments/${assignmentId}`);
  }

  // User management
  async getUserFormOptionsData() {
    return execute(GET, '/user-management/form-options');
  }

  async getUsersOverviewData(queryParams) {
    const queries = [];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (Array.isArray(queryParams.selectedTeams) && queryParams.selectedTeams.length >= 1) {
      queries.push(`selectedTeams=${queryParams.selectedTeams.map(({ id }) => id).join(',')}`);
    }

    if (
      Array.isArray(queryParams.selectedAssignments)
      && queryParams.selectedAssignments.length >= 1
    ) {
      queries.push(`selectedAssignments=${queryParams.selectedAssignments.map(({ id }) => id).join(',')}`);
    }

    if (
      Array.isArray(queryParams.selectedExploreAssignments)
      && queryParams.selectedExploreAssignments.length >= 1
    ) {
      queries.push(`selectedExploreAssignments=${queryParams.selectedExploreAssignments.map(({ id }) => id).join(',')}`);
    }

    if (
      Array.isArray(queryParams.selectedPermissionLevels)
      && queryParams.selectedPermissionLevels.length >= 1
    ) {
      queries.push(`selectedPermissionLevels=${queryParams.selectedPermissionLevels.map(({ id }) => id).join(',')}`);
    }

    if (Array.isArray(queryParams.selectedRoles) && queryParams.selectedRoles.length >= 1) {
      queries.push(`selectedRoles=${queryParams.selectedRoles.map(({ id }) => id).join(',')}`);
    }

    if (queryParams.selectedUserStatus) {
      queries.push(`selectedUserStatus=${queryParams.selectedUserStatus.id}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/user-management/overview${queryString}`);
  }

  async getUsersListing(queryParams) {
    const queryString = buildQueryForUsersListing(queryParams);

    return execute(GET, `/user-management/listing${queryString}`);
  }

  async downloadUsersListing(queryParams) {
    const queryString = buildQueryForUsersListing(queryParams);

    return execute(GET, `/user-management/listing/download${queryString}`);
  }

  async createNewUser(userData) {
    return execute(POST, '/user-management', userData);
  }

  async deleteUser(userID) {
    return execute(DELETE, `/user-management/${userID}`);
  }

  async uploadUserAvatar(avatarData) {
    return execute(POST, '/user-management/avatar', avatarData);
  }

  async updateUserViaUserManagement(userData) {
    return execute(PUT, `/user-management/${userData.id}`, userData);
  }

  async validateBulkCreateUsers(userList) {
    return execute(POST, '/user-management/bulk-create/validate', userList);
  }

  async bulkCreateUsers(userList) {
    return execute(POST, '/user-management/bulk-create', userList);
  }

  async validateBulkCreateMoments(momentsList, simulationId) {
    return execute(POST, `/moments/bulk-create/validate?simulationId=${simulationId}`, momentsList);
  }

  async bulkCreateMoments(momentsList, simulationId) {
    return execute(POST, `/moments/bulk-create?simulationId=${simulationId}`, momentsList);
  }

  // Welcome placemat
  async getWelcomePlacemat() {
    return execute(GET, '/welcome-placemat/');
  }

  async updateWelcomePlacemat(updatedData) {
    return execute(PUT, '/welcome-placemat/', updatedData);
  }

  async getAIRuleHistory(momentId) {
    return execute(GET, `/skills-ai/${momentId}`);
  }

  async getCountOfAIUserProgresses(momentId) {
    return execute(GET, `/user-progress/ai/count/${momentId}`);
  }

  async authenticateToAcademy(token) {
    return execute(POST, '/auth/academy', {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async resetTemporaryPassword(username, newPassword) {
    return execute(POST, '/users/reset-cognito-password', { username, newPassword });
  }

  // CONTENT AREA
  async createContentArea(simulationId, contentArea) {
    return execute(POST, `/simulations/${simulationId}/content-area/new`, contentArea);
  }

  async getContentAreasWithPublishedAIConversations() {
    return execute(GET, '/admin/content-area/published-ai-conversations');
  }

  // AI CONVERSATION BUILDER
  async cloneContentArea({
    contentAreaIdToBeCloned,
    simulationId,
    createNewSimulation,
    simulationName,
  }) {
    return execute(POST, '/admin/content-area/clone/', {
      contentAreaIdToBeCloned,
      simulationId,
      createNewSimulation,
      simulationName,
    });
  }

  async getAIConversationBuilder(contentAreaId) {
    return execute(GET, `/admin/ai-conversation/${contentAreaId}`);
  }

  async getAIConversationBuilderVersions(contentAreaId) {
    return execute(GET, `/admin/ai-conversation/versions/${contentAreaId}`);
  }

  async getAIConversationBuilderAnalysisData({ contentAreaId, versionId = null }) {
    const queryParams = [];
    if (versionId) {
      queryParams.push(`versionId=${versionId}`);
    }
    return execute(GET, `/admin/ai-conversation/analyze/${contentAreaId}?${queryParams.join(',')}`);
  }

  async getVersionedNode({ nodeUUID, versionId }) {
    return execute(GET, `/admin/ai-conversation/node/version/${nodeUUID}?versionId=${versionId}`);
  }

  async updateAIConversationBuilderData(
    contentAreaId,
    versionId,
    stages,
    type,
    avatar,
    voiceId,
    voiceType,
    voiceStability,
    voiceSimilarity,
    voiceStyleExaggeration,
  ) {
    return execute(
      PUT,
      `/admin/ai-conversation/${contentAreaId}?versionId=${versionId}`,
      {
        type,
        stages,
        avatar,
        voiceId,
        voiceType,
        voiceStability,
        voiceSimilarity,
        voiceStyleExaggeration,
      },
    );
  }

  async updateAIRulesForBuilder(nodesToBeSendToInMoment) {
    return execute(POST, '/admin/ai-conversation/submitNodesToInMoment', { nodes: nodesToBeSendToInMoment });
  }

  async extractIntentExplanation(intentSamples) {
    return execute(POST, '/admin/ai-conversation/extract-intent-explanation', { intentSamples });
  }

  async testSubmission(submissionText, nodeUUID, node, rationale) {
    return execute(POST, '/admin/ai-conversation/testSubmission', {
      submissionText,
      nodeUUID,
      node,
      rationale,
    });
  }

  async testMomentSubmission(submissionText, momentId, skillsAI, rationale) {
    return execute(POST, '/admin/ai-conversation/testMomentSubmission', {
      submissionText,
      momentId,
      skillsAI,
      rationale,
    });
  }

  async publishAIConversation({ contentAreaId, versionId }) {
    return execute(POST, `/admin/ai-conversation/publish/${contentAreaId}?versionId=${versionId}`);
  }

  async getVoices() {
    return execute(GET, '/admin/ai-conversation/voices');
  }

  async getSubmissions({
    ancestorUUID,
    versionId,
    contentAreaId,
    includePagination,
    randomRecords,
    pageIndex,
    pageSize,
    searchText,
    selectedTeamIds,
    rating,
  }) {
    let query = '';
    if (randomRecords) {
      query += `&&randomRecords=${randomRecords}`;
    }
    if (includePagination) {
      query += `&&pageSize=${pageSize || 50}&&pageIndex=${pageIndex || 0}`;
    }
    if (searchText) {
      query += `&&searchText=${searchText}`;
    }
    if (selectedTeamIds?.length) {
      query += `&&selectedTeamIds=${selectedTeamIds.join(',')}`;
    }
    if (rating || rating === 0) {
      query += `&&rating=${rating}`;
    }

    return execute(GET, `/admin/ai-conversation/analyze/submissions/${ancestorUUID}?versionId=${versionId || ''}&&contentAreaId=${contentAreaId || ''}${query}`);
  }

  async getFilters({
    contentAreaId,
    ancestorUUID,
    nodeUUID,
    fetchLatestPublishedNode,
  }) {
    let query = '';
    if (nodeUUID) {
      query += `nodeUUID=${nodeUUID}`;
    }
    if (fetchLatestPublishedNode) {
      query += `&&fetchLatestPublishedNode=${true}`;
    }
    return execute(GET, `/admin/ai-conversation/analyze/filter/${ancestorUUID}?contentAreaId=${contentAreaId}&&${query}`);
  }

  async getNode({ ancestorUUID, contentAreaId, fetchLatestPublished = false }) {
    let query = '';
    if (contentAreaId) {
      query += `?contentAreaId=${contentAreaId}`;
    }

    if (fetchLatestPublished) {
      query += `&&isLatestPublished=${fetchLatestPublished}`;
    }

    return execute(GET, `/admin/ai-conversation/node/${ancestorUUID}${query}`);
  }

  async getNodesByContentArea(contentAreaId) {
    return execute(GET, `/admin/node/content-area/${contentAreaId}`);
  }

  async updateNode({ node, contentAreaId }) {
    return execute(PUT, `/admin/ai-conversation/node/${node.ancestorUUID}?contentAreaId=${contentAreaId}`, node);
  }

  async testSubmissions({ nodeUUID, submissionIds, rationale }) {
    return execute(POST, '/admin/ai-conversation/analyze/testSubmissions', { nodeUUID, submissionIds, rationale });
  }

  async uploadAudioForNode(uploadData, { nodeUUID, isLiveRecording }, setProgress) {
    return execute(
      POST,
      `/admin/ai-conversation/upload-audio/${nodeUUID}`,
      uploadData,
      {
        params: { isLiveRecording },
        'Content-Type': 'multipart/form-data',
      },
    );
  }

  async uploadAvatarForAIConversation(avatarData, aiConversationVersionId) {
    return execute(
      POST,
      `/admin/ai-conversation/upload-avatar/${aiConversationVersionId}`,
      avatarData,
    );
  }

  async getCognitoPasswordPolicy() {
    return execute(
      GET,
      '/user-management/cognito-password-policy',
    );
  }

  async deleteUsers(userIds) {
    return execute(POST, '/user-management/bulk-delete', {
      userIds,
    });
  }

  async getInMomentFormOptionsData() {
    return execute(GET, '/admin/in-moment/form-options');
  }

  async getInMomentListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (Array.isArray(queryParams.selectedCustomer) && queryParams.selectedCustomer.length >= 1) {
      queries.push(`selectedCustomer=${queryParams.selectedCustomer.map(({ id }) => id).join(',')}`);
    }

    if (Array.isArray(queryParams.selectedEnvironment)
      && queryParams.selectedEnvironment.length >= 1) {
      queries.push(`selectedEnvironment=${queryParams.selectedEnvironment.map(({ name }) => name).join(',')}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/admin/in-moment${queryString}`);
  }

  async deleteMomentsFromInMoment(momentsIds) {
    return execute(DELETE, '/admin/in-moment', { momentsIds });
  }

  async publishTTSAudiosOnPreview({ contentAreaId, versionId }) {
    return execute(POST, `/admin/ai-conversation/preview/${contentAreaId}?versionId=${versionId}`);
  }

  // OVERALL METRICS
  async getUserMetrics(customerId) {
    return execute(GET, '/admin/metrics/user', {}, { params: { customerId } });
  }

  async getTeamAssignmentMetrics(customerId) {
    return execute(GET, '/admin/metrics/team-assignment', {}, { params: { customerId } });
  }

  async getCoachActivityMetrics(customerId) {
    return execute(GET, '/admin/metrics/coach-activity', {}, { params: { customerId } });
  }

  async getContentSummaryMetrics(customerId) {
    return execute(GET, '/admin/metrics/content-summary', {}, { params: { customerId } });
  }

  async getSoftwareSimulationMetrics(customerId) {
    return execute(GET, '/admin/metrics/software-simulation', {}, { params: { customerId } });
  }

  async getAIUsageMetrics(customerId) {
    return execute(GET, '/admin/metrics/ai-usage', {}, { params: { customerId } });
  }

  // BADGES
  async getBadgesListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }

    if (Array.isArray(queryParams.selectedTeams)
      && queryParams.selectedTeams.length >= 1) {
      queries.push(`selectedTeams=${queryParams.selectedTeams.map(({ id }) => id).join(',')}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/badge/listing${queryString}`);
  }

  async createBadge(badge) {
    return execute(POST, '/badge', badge);
  }

  async updateBadge(badge) {
    return execute(PUT, `/badge/${badge.id}`, badge);
  }

  async deleteBadge(badgeId) {
    return execute(DELETE, `/badge/${badgeId}`);
  }

  async getCustomers() {
    return execute(GET, '/customers');
  }

  async getCustomerUploadFiles() {
    return execute(GET, '/customers/files');
  }

  async uploadCustomerFile(uploadData) {
    return execute(POST, '/customers/files', uploadData);
  }

  async getAnnouncements(lastIndex) {
    return execute(GET, `/admin/announcements?lastIndex=${lastIndex}`);
  }

  async markAnnouncementsAsRead() {
    return execute(PUT, '/admin/announcements/mark-as-read');
  }

  async authenticateToAdmin(token) {
    return execute(POST, '/auth/admin', {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async getGenAIConversationsList() {
    return execute(GET, '/gen-ai/get-simulation-configs');
  }

  async getLoginsHistory({
    userId,
    pageIndex = 0,
    pageSize = 10,
    sortKey = 'date_created',
    sortDirection = 'DESC',
  }) {
    return execute(GET, `/user-management/${userId}/events`, {}, {
      params: {
        pageIndex,
        pageSize,
        sortKey,
        sortDirection,
      },
    });
  }


  async getMomentActivity({
    userId,
    pageIndex = 0,
    pageSize = 10,
    sortKey = 'date_created',
    sortDirection = 'DESC',
  }) {
    return execute(GET, `/user-management/moment-activity/${userId}`, {}, { 
      params: { 
        pageIndex,
        pageSize,
        sortKey,
        sortDirection,
      },
    });
  }

  async getConversationActivity({
    userId,
    pageIndex = 0,
    pageSize = 10,
    sortKey = 'date_created',
    sortDirection = 'DESC',
  }) {
    return execute(GET, `/user-management/conversation-activity/${userId}`, {}, {
      params: {
        pageIndex,
        pageSize,
        sortKey,
        sortDirection,
      },
    });
  }

  async deleteMomentActivity(progressIds, userId) {
    return execute(DELETE, '/user-management/moment-activity/', { progressIds, userId });
  }

  async deleteConversationActivity(attemptsIds, userId) {
    return execute(DELETE, '/user-management/conversation-activity/', { attemptsIds, userId });
  }
}

export default new BrightApi();
