// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';

class LayerStore {
  layers = [];

  hoveredLayersId = [];

  creatingResource = false;

  getLayers = async () => {
    const { data } = await Api.getLayers();

    this.layers = data;
  }

  getLayer = async (layerId) => {
    const { data } = await Api.getLayer(layerId);
    this.currentLayer = data;
  }

  saveLayer = async () => {
    this.creatingResource = true;
    await Api.updateLayer(this.currentLayer);
    this.creatingResource = false;
  }

  createLayer = async (layer) => {
    this.creatingResource = true;
    let createdLayer = null;

    try {
      const { data } = await Api.createLayer(layer);
      createdLayer = data;
      this.creatingResource = false;

      if (!createdLayer) return null;

      this.layers = [...this.layers, createdLayer];

      if (!layer.layerGroupId) {
        const miscGroup = this.layerGroups.find(({ name }) => name === 'Misc.');

        this.layerGroups = this.layerGroups.map((lg) => {
          if (lg.id === miscGroup.id) {
            return {
              ...lg,
              layerIds: [...new Set([...(lg.layerIds || []), createdLayer.id])],
            };
          }

          return lg;
        });
      }
    } catch {
      this.creatingResource = false;

      return null;
    }

    return createdLayer;
  }

  updateLayer = async (layerId, toUpdate) => {
    const layer = await Api.updateLayer(layerId, toUpdate);

    this.layers = this.layers.map((l) => {
      if (l.id === toUpdate.id) {
        return layer;
      }

      return l;
    });
  }

  setHoveredLayers = (layers) => {
    this.hoveredLayersId = layers;
  }

  deleteLayer = async (layerId) => {
    await Api.deleteLayer(layerId);
    this.layers = this.layers.filter(({ id }) => id !== layerId);
  }

  getLayerGroups = async () => {
    const { data } = await Api.getLayerGroups();
    this.layerGroups = data;
  }

  createLayerGroup = async (layerGroup) => {
    this.creatingResource = true;
    const { data: createdLayerGroup } = await Api.createLayerGroup(layerGroup);
    this.creatingResource = false;
    return createdLayerGroup;
  }

  deleteLayerGroup = async (layerGroupId, layersInLayerGroup) => {
    await Api.deleteLayerGroup(layerGroupId);
    this.layerGroups = this.layerGroups.filter(({ id }) => id !== layerGroupId);
    this.layers = this.layers.filter(({ id }) => !layersInLayerGroup.includes(id));
  }

  addLayerToLayerGroup = async (layerGroup, layerId) => {
    if (!(layerGroup.layerIds && layerGroup.layerIds.includes(layerId))) {
      const toUpdate = {
        layerIds: [...new Set([...(layerGroup.layerIds || []), layerId])],
      };

      await Api.updateLayerGroup(layerGroup.id, toUpdate);
    }
  }

  refreshLayerLibrary = async () => {
    await this.getLayers();
    await this.getLayerGroups();
  }

  updateLayerGroup = async (layerGroupId, toUpdate) => {
    const layerGroup = await Api.updateLayerGroup(layerGroupId, toUpdate);

    this.layerGroups = this.layerGroups.map((lg) => {
      if (lg.id === layerGroupId) {
        return layerGroup.data;
      }

      return lg;
    });
  }
}

decorate(LayerStore, {
  getLayers: action,
  getLayer: action,
  createLayer: action,
  updateLayer: action,
  createLayerGroup: action,
  deleteLayerGroup: action,
  getLayerGroups: action,
  updateLayerGroup: action,
  addLayerToLayerGroup: action,
  refreshLayerLibrary: action,
  hoveredLayersId: observable,
  layers: observable,
  layerGroups: observable,
  deleteLayer: action,
});

export default new LayerStore();
