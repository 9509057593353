// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';
import authStore from '../auth/AuthStore';
import { frontendUrl } from '../../util/helpers';

class UserStore {
  user = null;

  isFetchingUserProfile = true;

  isAuthenticated = false;

  isSuperAdmin = false;

  ssoUrl = null;

  currentProject = '';

  showNoAccess = false;

  users = [];

  hideBrightHeader = false;

  vimeoAuthenticated = false;

  setUserProfile(user) {
    if (user.role !== 'admin') {
      this.showNoAccess = true;
    }

    this.isAuthenticated = true;
    this.user = user;
    this.isFetchingUserProfile = false;
    this.isSuperAdmin = user?.isSuperAdmin && user?.role === 'admin';
    authStore.start(); // start tracking auth session
  }

  canCreate(resource) {
    return true;
  }

  canEdit(resource) {
    return true;
  }

  canDelete(resource) {
    return true;
  }

  async login(username) {
    try {
      const { data } = await Api.login(username);
      if (data !== undefined) {
        this.setUserProfile(data);
      }
    } catch (e) {
      // TODO
    }
  }

  logout = async () => {
    await Api.logout();
    authStore.stop(); // stop tracking auth session

    if (this.user.customer_id === 16) {
      window.location.href = `${frontendUrl()}/trugreen`;
    } else if (this.user.customerUrl) {
      window.location.href = this.user.customerUrl;
    } else {
      window.location.href = `${frontendUrl(this.user)}/login`;
    }
  };

  async fetchUserProfile(force = false) {
    if (this.user !== null && !force) {
      return;
    }

    try {
      this.isFetchingUserProfile = true;
      const { status, data } = await Api.getUserProfile();

      if (status === 401) {
        this.isAuthenticated = false;
        this.showNoAccess = true;
        this.ssoUrl = data.ssoUrl;
      } else if (data !== undefined) {
        this.setUserProfile(data);
      }

      this.isFetchingUserProfile = false;
    } catch (e) {
      this.isFetchingUserProfile = false;
      this.showNoAccess = true;
      this.isAuthenticated = false;
    }
  }

  async getUsers() {
    const { data } = await Api.getUsers();
    this.users = data;
  }

  async createUser(user) {
    await Api.createUser(user);
  }

  async updateUser(user) {
    await Api.updateUser(user);
    const updatedUser = {
      ...this.user,
      ...user,
    };
    this.user = updatedUser;
  }

  async updateCurrentProject(currentProject) {
    try {
      await Api.updateCurrentProject(currentProject);
      await this.fetchUserProfile(true);
    } catch (e) {
      // TODO
    }
  }

  async adminLoginByToken() {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const token = searchParams.get('token');
    searchParams.delete('token');

    const cleanedSearch = searchParams.toString() ? `?${searchParams.toString()}` : '';
    window.history.replaceState({}, document.title, `${window.location.pathname}${cleanedSearch}`);

    if (token) {
      await Api.authenticateToAdmin(token);
    }
  }

  async impersonateCustomer(customer) {
    await Api.impersonateCustomer(customer);
    window.location.reload();
  }

  async revertImpersonation(userId) {
    await Api.undoImpersonation(userId);
    window.location.reload();
  }

  setHideBrightHeader = (hideBrightHeader) => {
    this.hideBrightHeader = hideBrightHeader;
  };
}

decorate(UserStore, {
  user: observable,
  users: observable,
  isFetchingUserProfile: observable,
  isAuthenticated: observable,
  isSuperAdmin: observable,
  showNoAccess: observable,
  ssoUrl: observable,
  fetchUserProfile: action,
  login: action,
  logout: action,
  impersonateCustomer: action,
  revertImpersonation: action,
  createUser: action,
  getUsers: action,
  vimeoAuthenticated: observable,
  setHideBrightHeader: action,
  hideBrightHeader: observable,
});

export default new UserStore();
